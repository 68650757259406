import { Link } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import { removeCustomer } from "../../redux/module/customer";
import TableButtons from "../../components/TableComponents/TableButtons";
import { Stack, Typography } from "@mui/material";
import { GppBad } from "@mui/icons-material";

function CustomerAccountsCountRenderer(params) {
  return (
    <span className="my-renderer" style={{ fontSize: 14 }}>
      <Link
        to={`/accounts/?customer__name=${params.data.name}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.accounts_count}
      </Link>
    </span>
  );
}

function CustomerNameRenderer(params) {
  return (
    <span className="my-renderer" style={{ fontSize: 14 }}>
      <Link
        to={`/customers/dashboard/${params.data.id}/personal`}
        style={{ textDecoration: "none" }}
      >
        {params.data.name}
      </Link>
    </span>
  );
}

function CustomerLinkRenderer(props) {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    await dispatch(removeCustomer(props.data.id));
  };
  return (
    <TableButtons
      link={`/customers/${props.data.id}`}
      handleDelete={handleDelete}
      deletePopupEntity="customer"
      historyData={props.data.history || []}
    />
  );
}

function CustomerAccountsRenderer(params) {
  return (
    <span className="my-renderer" style={{ fontSize: 14 }}>
      <Stack>
        {params.data.accounts.map((item) => (
          <Typography fontSize={14}>
            {item.name} ({item.internal_id})
          </Typography>
        ))}
      </Stack>
    </span>
  );
}

function AccountEmailsRenderer(params) {
  return (
    <span className="my-renderer">
      <Stack>
        {params.data.emails.map((item) => (
          <Typography>{item.email}</Typography>
        ))}
      </Stack>
    </span>
  );
}
const customer_name = {
  headerName: "Customer",
  field: "name",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => {
    return `${params.data.name}`;
  },
  cellRendererSelector: () => {
    return {
      component: CustomerNameRenderer,
    };
  },
  minWidth: 250,
};

const customer_email = {
  headerName: "Emails",
  field: "emails__email",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) =>
    params.data.emails.map((item) => item.email)?.join(", "),
  minWidth: 300,
  cellRendererSelector: () => {
    return {
      component: AccountEmailsRenderer,
    };
  },
};

const customer_group = {
  headerName: "Price Group",
  field: "group.name",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => params.data.group?.name || "N/A",
  minWidth: 150,
};

const customer_accounts_count = {
  headerName: "Accounts",
  field: "accounts_count",
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  autoHeight: true,
  cellRendererSelector: () => {
    return {
      component: CustomerAccountsCountRenderer,
    };
  },
  minWidth: 100,
};

const customer_address = {
  headerName: "Address",
  field: "address",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  minWidth: 350,
  cellStyle: { fontSize: 14 },
};
const customer_city = {
  headerName: "City",
  field: "city",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  minWidth: 150,
};
const customer_state = {
  headerName: "State",
  field: "state",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  minWidth: 100,
};

const customer_link = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  cellRenderer: CustomerLinkRenderer,
  minWidth: 120,
};

const customer_invoice_net = {
  headerName: "Invoice net",
  field: "invoice.net",
  wrapText: true,
  autoHeight: true,
  flex: 5,
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
  filter: true,
  floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  minWidth: 140,
  maxWidth: 140,
};

const customer_internal_id = {
  headerName: "Internal Id",
  field: "internal_id",
  wrapText: true,
  autoHeight: true,
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
  sort: "asc",
  filter: true,
  floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  minWidth: 120,
  maxWidth: 120,
  cellStyle: { fontSize: 14 },
  cellRenderer: (params) => (
    <Stack direction={"row"} spacing={1}>
      {params?.data?.active === false && (
        <GppBad color="red" style={{ color: "orange" }} />
      )}
      <Typography>
        {params.data.internal_id || params.data.customer.internal_id}
      </Typography>
    </Stack>
  ),
};

const customer_accounts = {
  headerName: "Accounts",
  field: "accounts",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting

  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
    textMatcher: () => true,
  },
  filter: true,
  floatingFilter: true,
  minWidth: 350,
  cellRenderer: CustomerAccountsRenderer,
};
const customerTableColumns = [
  customer_internal_id,
  customer_name,
  customer_address,
  customer_accounts,
  //customer_city,
  //customer_state,
  customer_email,
  //customer_group,
  // customer_invoice_net,
  customer_link,
];

export default customerTableColumns;
