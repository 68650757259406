import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  OutlinedInput,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { getNoPages, useSearchDebounce } from "../../utils";
import PageLoading from "../../components/PageLoading";
import CustomerSelect from "../../components/AsyncSelects/CustomerSelect";
import { smallButton } from "../../utils/smallButton";
import {
  createCustomersDailyReminder,
  createMessage,
  customersDailyReminderList,
  ListMessages,
  listSmsRooms,
  updateSmsRoom,
} from "../../distflowAPI/customersApi";
import dayjs from "dayjs";
import {
  DATE_FORMAT_FRONT,
  DATE_FORMAT_FRONT_H_M,
} from "../../utils/constants";
import relativeTime from "dayjs/plugin/relativeTime";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";

dayjs.extend(relativeTime);

const SmsRooms = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    ordering: "customer",
    search: "",
  });
  const [customersLoading, setCustomersLoading] = useState(false);
  const [smsRooms, setSmsRooms] = useState([]);
  const [search, setSearch] = useSearchDebounce(200);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const [activeRoom, setActiveRoom] = useState(null);

  function refreshTable() {
    listSmsRooms(search).then((res) => setSmsRooms(res.data.results));
  }
  useEffect(() => {
    refreshTable();
  }, [search]);

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams]);
  const handleChange = (field, value) => {
    if (field === "orderBy") {
      handleSortParams(value);
    }
  };
  function handleSortParams(value) {
    let newSortingValue = "";
    if (value?.length > 0) {
      newSortingValue = value[0]?.field;
      if (value[0]?.sort != "asc") {
        newSortingValue = "-" + newSortingValue;
      }
    }
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("ordering", newSortingValue);
      return newParams;
    });
  }

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  return (
    <>
      <PageLoading dataLoading={customersLoading} />

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        marginBottom={5}
      >
        <Typography
          fontSize="23px"
          fontWeight={800}
          component="h2"
          data-testid="page-title"
        >
          Sms messages
        </Typography>
        <Button
          onClick={() => {
            setOpenAddCustomer(true);
          }}
          variant="contained"
          sx={{ minWidth: "10%" }}
        >
          New Chat
        </Button>
      </Stack>

      <Grid container spacing={2}>
        <Grid item md={3}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Chats
              </Typography>
              {smsRooms.map((room) => (
                <div
                  onClick={() => setActiveRoom(room)}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "10px",
                    cursor: "pointer",
                    backgroundColor:
                      activeRoom?.id === room.id ? "#f0f0f0" : "white",
                  }}
                >
                  <Typography style={{ fontWeight: "bold" }} variant="caption">
                    {room.account?.name || "Unknown"}{" "}
                    {room?.last_message?.timestamp && (
                      <Typography variant="caption">
                        {dayjs(room?.last_message?.timestamp).fromNow()}
                      </Typography>
                    )}
                  </Typography>

                  <Typography variant="caption">{room.to_number}</Typography>
                </div>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={9}>
          <RoomRenderer room={activeRoom} refreshTable={refreshTable} />
        </Grid>
      </Grid>
    </>
  );
};

export default SmsRooms;

function MessageIn({ message }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
      }}
    >
      <Typography
        style={{
          backgroundColor: "#f0f0f0",
          padding: "10px",
          width: "fit-content",
          borderRadius: "10px",
        }}
      >
        {message.text}
        <br />
        <small style={{ fontSize: 8 }}>
          {dayjs(message.timestamp).format(DATE_FORMAT_FRONT_H_M)}
        </small>
      </Typography>
    </div>
  );
}

function MessageOut({ message }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <Typography
        style={{
          backgroundColor: "#f0f0f0",
          padding: "10px",
          width: "fit-content",
          borderRadius: "10px",
        }}
      >
        {message.text}
        <br />
        <small style={{ fontSize: 8 }}>
          {dayjs(message.timestamp).format(DATE_FORMAT_FRONT_H_M)}
        </small>
      </Typography>
    </div>
  );
}

function RoomRenderer({ room, refreshTable }) {
  const [messages, setMessages] = useState([]);
  const [openSelectCustomer, setOpenSelectCustomer] = useState(false);
  const [fetchingMessages, setFetchingMessages] = useState(false);
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  function handleSendMessage() {
    if (isSending) {
      return;
    }
    setIsSending(true);
    const payload = {
      room: room?.id,
      text: message,
    };
    createMessage(room?.id, payload)
      .then((res) => {
        console.log("res is: ", res);
        setMessages([...messages, res.data]);
        setMessage("");
        setIsSending(false);
      })
      .finally(() => setIsSending(false));
  }
  useEffect(() => {
    if (room && room?.id) {
      setFetchingMessages(true);
      ListMessages(room?.id).then((res) => {
        setMessages(res.data.results);
        setFetchingMessages(false);
      });
    }
  }, [room]);
  if (!room) {
    return <>Select a chat from the left bar!</>;
  }

  return (
    <Card sx={{ minHeight: "640px" }}>
      <SelectCustomerModal
        isOpen={openSelectCustomer}
        setIsOpen={setOpenSelectCustomer}
        activeRoom={room}
        refreshTable={refreshTable}
      />
      {fetchingMessages ? (
        <Typography>Loading...</Typography>
      ) : (
        <CardContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
            }}
          >
            {!room.account?.name && (
              <Typography
                variant="caption"
                style={{
                  color: "blue",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  fontSize: "0.6rem",
                }}
                onClick={() => {
                  setOpenSelectCustomer(true);
                }}
              >
                Assign to an account
              </Typography>
            )}
            <Typography variant="subtitle1">{room?.account?.name}</Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "grey",
                fontSize: "0.8rem",
              }}
            >
              {room?.to_number}
            </Typography>
          </div>
          <Divider
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />
          <div
            style={{
              height: "500px",
              overflowY: "scroll",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {messages.map((message) =>
              message.from_number === room.to_number ? (
                <MessageIn key={message.id} message={message} />
              ) : (
                <MessageOut key={message.id} message={message} />
              )
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              itemAlign: "center",
              marginTop: "10px",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type your message here"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              variant="contained"
              disabled={true} //isSending}
              onClick={() => {
                //handleSendMessage();
              }}
            >
              Send
            </Button>
          </div>
        </CardContent>
      )}
    </Card>
  );
}

function SelectCustomerModal({ isOpen, setIsOpen, activeRoom, refreshTable }) {
  const [selectedCustomer, setSelectedCustomer] = useState({
    label: "select",
    value: null,
  });
  function handleSave() {
    if (!selectedCustomer?.value) {
      alert("select a customer");
      return;
    }
    updateSmsRoom(activeRoom.id, { account: selectedCustomer.value }).then(
      (res) => {
        refreshTable();
        setIsOpen(false);
      }
    );
  }
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Select Customer</DialogTitle>

      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
        }}
      >
        <AccountSelect
          label="Account"
          name="account"
          required
          value={selectedCustomer}
          onChange={(_e, newValue) => {
            setSelectedCustomer(newValue);
          }}
        />

        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleSave();
              //
            }}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
