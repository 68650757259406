import { createBrowserRouter, Navigate } from "react-router-dom";
import ProtectedLayout from "./components/ProtectedLayout";
import Accounts from "./pages/accounts";
import AddProduct from "./pages/addProduct";
import AddEditRoute from "./pages/addEditRoute";
import AddRouteChanges from "./pages/addRouteChanges";
import RoutesChangesOld from "./pages/routesChanges";
import RoutesChanges from "./pages/routes2/routesChanges";
import Login from "./pages/auth/login";
import Categories from "./pages/categories";
import UpdateCategory from "./pages/categories/UpdateCategory";
import Products from "./pages/products";
import UpdateProduct from "./pages/products/updateProduct";
import RoutesReport from "./pages/routesReport";
import Route from "./pages/route";
import Tags from "./pages/tags";
import Vendors from "./pages/vendors";
import Customers from "./pages/cutsomers";
import Variant from "./pages/products/variants";
import Routes from "./pages/routes";
import AuthLayout from "./components/AuthLayout";
import ErrorBoundary from "./components/ErrorBoundary";
import Territories from "./pages/territories";
import Orders from "./pages/orders";
import ManageAutoOrders from "./pages/manageAutoOrders";
import UpdateAccount from "./pages/accounts/UpdateAccount";
import UpdateCustomer from "./pages/cutsomers/UpdateCustomer";
import UpdateVendor from "./pages/vendors/UpdateVendor";
import Settings from "./pages/settings/listSettings";
import CreateUpdateSetting from "./pages/settings/CreateUpdateSetting";
import UpdateTerritory from "./pages/territories/UpdateTerritory";
import Managers from "./pages/managers";
import Variants from "./pages/variants";
import UpdateTag from "./pages/tags/UpdateTag";
import Drivers from "./pages/routes2/drivers";
import RoutesTwo from "./pages/routes2/routes";
import AddEditRoute2 from "./pages/routes2/addEditRoute";
import ListInvoices from "./pages/invoices";
import ListPayments from "./pages/payments";
import RoutesReportsTwo from "./pages/routes2/reports/reports2";
import InvoiceDetail from "./pages/invoices/invoice";
import PaymentDetail from "./pages/payments/PaymentDetail";
import CustomerDashboard from "./pages/cutsomers/CustomerDashboard";
import UpdatePayment from "./pages/payments/UpdatePayment";
import UpdateGroup from "./pages/groups/UpdateGroup";
import Groups from "./pages/groups";
import VendorCategoryOverrides from "./pages/vendorCategoryOverrides";
import ManageUserAccounts from "./pages/users/ManageUserAccounts";
import UpdateManager from "./pages/managers/updateManager";
import StatementReports from "./pages/statements";
import VendorReportComparator from "./pages/VRC";
import BankTransactions from "./pages/bankDeposits";
import BankTransactionsBatch from "./pages/bankDeposits/batchTransactions";
import ProfitAndLoss from "./pages/pnl";
import Credits from "./pages/credits";
import Transactions from "./pages/transactions";
import Transaction from "./pages/transactions/update";
import Salesmen from "./pages/salesmen";
import UpdateSalesmen from "./pages/salesmen/UpdateSalesmen";
import GroupStatements from "./pages/groupStatements";
import Batches from "./pages/groupStatements/batches";
import StatementsList from "./pages/groupStatements/weeklyStatements";
import CreatePaymentPage from "./pages/payments/CreatePayment";
import InvoiceCreateUpdate from "./pages/invoices/invoiceCreateUpdate";
import Collections from "./pages/invoices/collections";
import EmailSend from "./pages/cutsomers/emailsend";
import EmailQueue from "./pages/emailQueue";
import WhitelistedIps from "./pages/whitelistedIps";
import OtpVerification from "./pages/auth/otpVerification";
import Notifications from "./pages/notifications";
import CCBankTransactions from "./pages/ccBankDeposits";
import CCBankTransactionsBatch from "./pages/ccBankDeposits/batchTransactions";
import CustomersProductManagement from "./pages/cutsomers/customersProductsVisibilityManagement";
import CustomerPortalAlert from "./pages/cpAlerts";
import CustomersDailyReminder from "./pages/cutsomers/dailyReminders";
import SmsRooms from "./pages/cutsomers/smsMessaging";

export const router = createBrowserRouter([
  {
    path: "/categories",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/categories",
        element: <Categories />,
      },
      {
        path: "/categories/:id",
        element: <UpdateCategory />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/tags",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/tags",
        element: <Tags />,
      },
      {
        path: "/tags/:id",
        element: <UpdateTag />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/groups",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/groups",
        element: <Groups />,
      },
      {
        path: "/groups/:id",
        element: <UpdateGroup />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/groups",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/groups",
        element: <Groups />,
      },
      {
        path: "/groups/:id",
        element: <UpdateGroup />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/credits",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/credits",
        element: <Credits />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/vendor-category-overrides",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/vendor-category-overrides",
        element: <VendorCategoryOverrides />,
      },
      // {
      //   path: "/vendor-category-overrides/:id",
      //   element: <UpdateGroup />,
      // },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/vendors",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/vendors",
        element: <Vendors />,
      },
      {
        path: "/vendors/:id",
        element: <UpdateVendor />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/accounts",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/accounts",
        element: <Accounts />,
      },
      {
        path: "/accounts/:id",
        element: <UpdateAccount />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/customers",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/customers/daily_reminders",
        element: <CustomersDailyReminder />,
      },
      {
        path: "/customers/visible_items",
        element: <CustomersProductManagement />,
      },
      {
        path: "/customers",
        element: <Customers />,
      },
      {
        path: "/customers/:id",
        element: <UpdateCustomer />,
      },
      {
        path: "/customers/dashboard/:id/:tab",
        element: <CustomerDashboard />,
      },
      {
        path: "/customers/emailsend",
        element: <EmailSend />,
      },
      {
        path: "/customers/sms-rooms",
        element: <SmsRooms />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/variants",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/variants",
        element: <Variants />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/managers",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/managers",
        element: <Managers />,
      },
      {
        path: "/managers/:id",
        element: <UpdateManager />,
      },
      {
        path: "/managers/:id/accounts",
        element: <ManageUserAccounts />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/salesmen",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/salesmen",
        element: <Salesmen />,
      },
      {
        path: "/salesmen/:id",
        element: <UpdateSalesmen />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/products",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/products",
        element: <Products />,
      },
      {
        path: "/products/add-product",
        element: <AddProduct />,
      },
      {
        path: "/products/:id",
        element: <UpdateProduct />,
      },
      {
        path: "/products/:id/variants/:vid",
        element: <Variant />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/routes",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/routes/routes",
        element: <Routes />,
      },
      {
        path: "/routes/routes-report",
        element: <RoutesReport />,
      },
      {
        path: "/routes/route",
        element: <Route />,
      },
      {
        path: "/routes/routes-changes",
        element: <RoutesChangesOld />,
      },
      {
        path: "/routes/add-route",
        element: <AddEditRoute />,
      },
      {
        path: "/routes/edit-route/:id",
        element: <AddEditRoute />,
      },
      {
        path: "/routes/add-route-change",
        element: <AddRouteChanges />,
      },
      {
        path: "/routes/edit-route-change/:id",
        element: <AddRouteChanges />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/routes2",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/routes2/routes",
        element: <RoutesTwo />,
      },
      {
        path: "/routes2/routes-changes",
        element: <RoutesChanges />,
      },
      {
        path: "/routes2/routes-changes/:id",
        element: <AddEditRoute2 isChanges />,
      },
      {
        path: "/routes2/drivers",
        element: <Drivers />,
      },
      {
        path: "/routes2/routes/:id",
        element: <AddEditRoute2 />,
      },
      {
        path: "/routes2/routes_reports",
        element: <RoutesReportsTwo />,
      },
      {
        path: "/routes2/routes_reports_two",
        element: <RoutesReportsTwo />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/territories",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/territories",
        element: <Territories />,
      },
      {
        path: "/territories/:id",
        element: <UpdateTerritory />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/orders",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/orders",
        element: <Orders />,
      },
      {
        path: "/orders/manage-auto-orders",
        element: <ManageAutoOrders />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/invoices",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/invoices/invoices",
        element: <ListInvoices />,
      },
      {
        path: "/invoices/collections",
        element: <Collections />,
      },
      {
        path: "/invoices/invoices/:id",
        element: <InvoiceCreateUpdate />,
      },
      {
        path: "/invoices/payments",
        element: <ListPayments />,
      },
      {
        path: "/invoices/payments/:id/from-invoice",
        element: <UpdatePayment />,
      },
      {
        path: "/invoices/payments/:id",
        element: <CreatePaymentPage />,
      },
      {
        path: "/invoices/payments/:id/details",
        element: <PaymentDetail />,
      },
      {
        path: "/invoices/statement_reports",
        element: <StatementReports />,
      },
      {
        path: "/invoices/vendor_report_comparator",
        element: <VendorReportComparator />,
      },
      {
        path: "/invoices/bank_transactions",
        element: <BankTransactions />,
      },
      {
        path: "/invoices/bank_transactions_batch/:id",
        element: <BankTransactionsBatch />,
      },
      {
        path: "/invoices/cc_transactions",
        element: <CCBankTransactions />,
      },
      {
        path: "/invoices/cc_bank_transactions_batch/:id",
        element: <CCBankTransactionsBatch />,
      },
      {
        path: "/invoices/transactions",
        element: <Transactions />,
      },
      {
        path: "/invoices/transactions/:id",
        element: <Transaction />,
      },
      {
        path: "/invoices/group-statements/",
        element: <GroupStatements />,
      },
      {
        path: "/invoices/group-statements/:id/batches",
        element: <Batches />,
      },
      {
        path: "/invoices/weekly-invoices",
        element: <StatementsList />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/settings",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/settings/:id",
        element: <CreateUpdateSetting />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/cp_alerts",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/cp_alerts",
        element: <CustomerPortalAlert />,
      },
      {
        path: "/cp_alerts/:id",
        element: <CreateUpdateSetting />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/email-queue",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/email-queue",
        element: <EmailQueue />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/whitelisted-ips",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/whitelisted-ips",
        element: <WhitelistedIps />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/notifications",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/notifications",
        element: <Notifications />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/pnl",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/pnl",
        element: <ProfitAndLoss />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "/auth/otp",
        element: <OtpVerification />,
        index: true,
      },
      {
        path: "/auth/login",
        element: <Login />,
        index: true,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "*",
    element: <Navigate to="/auth/login" />,
    errorElement: <ErrorBoundary />,
  },
]);
