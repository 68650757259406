import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  OutlinedInput,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { getNoPages, useSearchDebounce } from "../../utils";
import PageLoading from "../../components/PageLoading";
import CustomerSelect from "../../components/AsyncSelects/CustomerSelect";
import { smallButton } from "../../utils/smallButton";
import {
  createCustomersDailyReminder,
  customersDailyReminderList,
  deleteCustomersDailyReminder,
  importDailyReminderList,
} from "../../distflowAPI/customersApi";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import ConfirmDialog from "../../components/ConfirmDialog";

const CustomersDailyReminder = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    ordering: "customer",
    search: "",
  });
  const [customersLoading, setCustomersLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [search, setSearch] = useSearchDebounce(200);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [reminderToDelete, setReminderToDelete] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  function refreshTable() {
    customersDailyReminderList(search).then((res) =>
      setCustomers(res.data.results)
    );
  }
  useEffect(() => {
    refreshTable();
  }, [search]);

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams]);
  const handleChange = (field, value) => {
    if (field === "orderBy") {
      handleSortParams(value);
    }
  };
  function handleSortParams(value) {
    let newSortingValue = "";
    if (value?.length > 0) {
      newSortingValue = value[0]?.field;
      if (value[0]?.sort != "asc") {
        newSortingValue = "-" + newSortingValue;
      }
    }
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("ordering", newSortingValue);
      return newParams;
    });
  }
  function handleDelete(item) {
    deleteCustomersDailyReminder(item?.id).then((res) => {
      refreshTable();
      setReminderToDelete(null);
      setIsOpenDelete(false);
    });
  }
  function handleImport() {
    setIsImporting(true);
    importDailyReminderList().then((res) => setIsImporting(false));
  }
  return (
    <>
      <PageLoading dataLoading={customersLoading} />

      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Customers Daily Reminder
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <OutlinedInput
          sx={{ height: "40px", minWidth: "70%" }}
          name="Search"
          placeholder="Search"
          fullWidth
          value={searchParams.get("search")}
          onChange={(e) => {
            setSearchParams((params) => {
              const newParams = new URLSearchParams(params);
              newParams.set("search", e.target.value);
              return newParams;
            });
          }}
        />
        <Button
          onClick={() => {
            setOpenAddCustomer(true);
          }}
          variant="contained"
          sx={{ minWidth: "10%" }}
        >
          New Reminder
        </Button>
        {/** 
         * <Button
          onClick={() => {
            handleImport();
          }}
          variant="contained"
          sx={{ minWidth: "10%" }}
          color={"secondary"}
          disabled={isImporting}
        >
          Import From Accounts
        </Button>
        */}
      </Stack>
      <Grid container spacing={2}>
        {customers.map((customer) => (
          <Grid item md={4}>
            <Card sx={{ minHeight: 80, padding: 3 }}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack>
                  <Typography>Customer: {customer?.account?.name}</Typography>
                  <Typography>Phone: {customer?.phone_number}</Typography>
                  <Typography>
                    Last Reminder:{" "}
                    {customer?.last_reminder
                      ? dayjs(customer.last_reminder).format(DATE_FORMAT_FRONT)
                      : "N/A"}
                  </Typography>
                </Stack>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setIsOpenDelete(true);
                    setReminderToDelete(customer);
                  }}
                >
                  Delete
                </Button>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>
      <ConfirmDialog
        title="Delete daily reminder?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={() => handleDelete(reminderToDelete)}
      >
        {`Are you sure you want to delete this ${reminderToDelete?.account?.name} daily reminder?`}
      </ConfirmDialog>
      <AddCustomerModal
        open={openAddCustomer}
        setOpen={(val) => setOpenAddCustomer(val)}
        existing_customers={customers}
        refreshTable={refreshTable}
      />
    </>
  );
};

export default CustomersDailyReminder;

function AddCustomerModal({ open, setOpen, refreshTable, existing_customers }) {
  const [errors, setErrors] = useState({});
  const [phone, setPhone] = useState("");
  const [customer, setCustomer] = useState({ label: "select", value: -1 });
  const [isSaving, setIsSaving] = useState(false);

  function validatePhoneNumber(phoneNumber) {
    const phoneRegex = /^\+\d{1,3}\d{10}$/;
    return phoneRegex.test(phoneNumber);
  }
  function allowOnlyPhoneChars(val) {
    const allowedChars = /^[\d\+]*$/;
    let input = val;

    if (!allowedChars.test(input)) {
      input = input.slice(0, -1);
    }
    return input;
  }
  function handleAddReminder() {
    setIsSaving(true);
    const payload = {
      account: customer?.value,
      phone_number: phone,
    };
    console.log("payload is: ", payload);
    createCustomersDailyReminder(payload)
      .then((res) => {
        refreshTable();
        setOpen(false);
      })
      .finally(() => {
        setIsSaving(false);
      });
  }
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={850}>
      <DialogTitle>Add account</DialogTitle>
      <DialogContent>
        <Stack padding={2} spacing={1}>
          <AccountSelect
            label="Account"
            name="account"
            required
            value={customer}
            onChange={(_e, newValue) => {
              console.log("setting custome:r ", newValue);
              setCustomer(newValue);
            }}
          />
          <small>Phone should be in the following format +1 555 555 5555</small>
          <TextField
            label="Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <small
            style={{ color: validatePhoneNumber(phone) ? "green" : "red" }}
          >
            {validatePhoneNumber(phone)
              ? "valid phone number"
              : "invalid phone number"}
          </small>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color={"success"}
            onClick={() => handleAddReminder()}
            disabled={!validatePhoneNumber(phone)}
          >
            Add
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
