import {
  Button,
  Typography,
  Box,
  Grid,
  Checkbox,
  Snackbar,
  Alert,
  TextField,
  Select,
  Autocomplete,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useState, useEffect } from "react";
import Block from "../../../components/Block";
import CustomerSelect from "../../../components/AsyncSelects/CustomerSelect";
import AddIcon from "@mui/icons-material/Add";
// import AccountSelect from "../../../components/AsyncSelects/AccountSelect";
import CustomerCard from "./CustomerCard";
import { emailsReducer, EMAILS_INIT, REDUCER_ACTIONS } from "./config";
import AttachmentComponent from "./AttachmentComponent";
import { useReducer } from "react";

import { sendEmail } from "../../../distflowAPI/customersApi";
import AccountSelect from "../../../components/AsyncSelects/AccountSelect";
import AccountTypeSelect from "../../../components/AsyncSelects/AccountTypeSelect";
export default function EmailSend() {
  const [data, dispatch] = useReducer(emailsReducer, EMAILS_INIT);
  const [content, setContent] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [editorKey, setEditorKey] = useState(0);
  const [subject, setSubject] = useState("");
  const [result, setResult] = useState({
    valid_customers: [],
    invalid_customers: [],
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCheckBoxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const [selectedCustomer, setSelectedCustomer] = useState({
    label: "",
    value: "",
  });
  const [selectedCustomerExclude, setSelectedCustomerExclude] = useState({
    label: "",
    value: "",
  });
  const handleFileChange = (event) => {
    dispatch({
      type: REDUCER_ACTIONS.ADD_FILE,
      payload: event.target.files,
    });
  };

  const [customersList, setCustomersList] = useState([]);
  const [customersListExclude, setCustomersListExclude] = useState([]);
  const [correspondanceType, setCorrespondanceType] = useState({
    value: "SELECTED",
    label: "Selected accounts",
  });
  const [accountType, setAccountType] = useState({ value: "", label: "" });
  const CORRESPONDANCE_TYPES = [
    {
      value: "ALL",
      label: "all accounts",
    },
    {
      value: "SELECTED",
      label: "selected accounts",
    },
    {
      value: "3MONTHS",
      label: "ordered 3 months",
    },
    {
      value: "ATYPE",
      label: "Account Type",
    },
  ];
  console.log(selectedCustomer);
  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  function handleEmailSend() {
    setIsSending(true);
    const customersArray = data.customers_list.map(
      (customer) => customer.value
    );
    const excludedCustomersArray = data.excluded_customers_list.map(
      (customer) => customer.value
    );
    const payload = new FormData();
    if (correspondanceType == "SELECTED" && customersArray.length == 0) {
      alert("Select at least one account");
      setIsSending(false);
      return;
    }
    if (correspondanceType == "ATYPE" && !accountType?.value) {
      alert("Select an account type");
      setIsSending(false);
      return;
    }
    payload.append("correspondance_type", correspondanceType.value);
    payload.append("customers_ids", customersArray);
    payload.append("excluded_customers_ids", excludedCustomersArray);
    payload.append("account_type", accountType?.value);
    if (subject) {
      payload.append("subject", subject);
    }
    payload.append("content", content);
    if (data.email_files) {
      for (let i = 0; i < data.email_files.length; i++) {
        let file = data.email_files[i];
        payload.append(`attachments.${i}`, file, file.name);
        console.log("attaching ", file, file.name);
      }
    }
    for (let [key, value] of payload.entries()) {
      console.log(`${key}: ${value}`);
    }
    console.log("payload is: ", payload);
    sendEmail(payload)
      .then((res) => {
        setResult({
          valid_customers: res.data.valid_customers,
          invalid_customers: res.data.invalid_customers,
        });
        setIsSending(false);
        setContent("");
        setSnackbarMessage("Email sent successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setSubject("");
        setEditorKey((prevKey) => prevKey + 1);
      })
      .catch((err) => {
        setIsSending(false);
        setContent("");
        setSnackbarMessage("Failed to send email.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setSubject("");
        setEditorKey((prevKey) => prevKey + 1);
      });
  }

  return (
    <>
      {result.invalid_customers.length > 0 && (
        <>
          <Alert color="error" sx={{ mb: 1 }}>
            Couldn't email following customers (no emails on file):{" "}
            {result.invalid_customers.join(", ")}
          </Alert>
          <Alert color="error" sx={{ mb: 1 }}>
            Couldn't email {result.invalid_customers.length} customers (no
            emails on file)
          </Alert>
        </>
      )}
      {result.valid_customers.length > 0 && (
        <Alert color="success" sx={{ mb: 1 }}>
          Emailing: {result.valid_customers.length} customers
        </Alert>
      )}
      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
        mb="10px"
      >
        Email Correspondence
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "5px",
        }}
      >
        <TextField
          fullWidth
          sx={{ width: "75%" }}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Email Subject..."
        />
        <Button
          variant="contained"
          color="success"
          onClick={handleEmailSend}
          disabled={isSending}
        >
          Send Email
        </Button>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <Editor
            key={editorKey}
            apiKey="gpr4nec4hr3fdzfph9jv42wv8eh6qdn41p2gneq5h7kq8cpy"
            initialValue=""
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
            }}
            onEditorChange={handleEditorChange}
          />
          <Block sx={{ mt: "20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <Typography sx={{ fontSize: "20px", fontWeight: "bolder" }}>
                Attachment Files
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button variant="contained" color="warning" component="label">
                  Upload Files
                  <input type="file" hidden onChange={handleFileChange} />
                </Button>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {data?.email_files.map((file) => {
                return <AttachmentComponent file={file} dispatch={dispatch} />;
              })}
            </Box>
          </Block>
        </Grid>
        <Grid item xs={3}>
          <Block>
            <Typography>Correspondance type</Typography>
            <Autocomplete
              options={CORRESPONDANCE_TYPES}
              value={correspondanceType}
              onChange={(e, v) => {
                setCorrespondanceType(v);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Correspondance type" />
              )}
            />
          </Block>
          {correspondanceType.value == "SELECTED" && (
            <Block>
              <Typography
                fontSize="18px"
                fontWeight={600}
                component="h2"
                data-testid="page-title"
              >
                Accounts List:
                {data?.customers_list.length > 0 && (
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    ({data.customers_list.length} account(s) selected)
                  </span>
                )}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  mb: "20px",
                }}
              >
                <AccountSelect
                  label="Add an account"
                  name="account"
                  required
                  customersList={data?.customers_list}
                  disabled={isChecked}
                  value={selectedCustomer}
                  onChange={(_e, newValue) => setSelectedCustomer(newValue)}
                  extraStyles={{
                    mt: 1,
                  }}
                />

                <Button
                  sx={{
                    padding: "4px 5px",
                    fontSize: "12px",
                    minWidth: "0",
                    width: "30px",
                    height: "30px !important",
                    mt: "7px",
                  }}
                  variant="contained"
                  disabled={isChecked}
                  onClick={() => {
                    dispatch({
                      type: REDUCER_ACTIONS.ADD_CUSTOMER,
                      payload: { newCustomer: selectedCustomer },
                    });
                    setSelectedCustomer({ label: "", value: "" });
                  }}
                  color="primary"
                >
                  <AddIcon />
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                {data.customers_list.map((customer) => {
                  return (
                    <CustomerCard
                      customer={customer}
                      setCustomersList={setCustomersList}
                      dispatch={dispatch}
                    />
                  );
                })}
              </Box>
            </Block>
          )}
          {correspondanceType.value == "ATYPE" && (
            <Block>
              <Typography
                fontSize="18px"
                fontWeight={600}
                component="h2"
                data-testid="page-title"
              >
                Account Type
              </Typography>
              <AccountTypeSelect
                label="Account Type"
                name="account_type"
                required={true}
                value={accountType}
                onChange={(_e, newValue) => setAccountType(newValue)}
              />
            </Block>
          )}
          {(correspondanceType.value == "ALL" ||
            correspondanceType.value == "3MONTHS" ||
            correspondanceType.value == "ATYPE") && (
            <Block>
              <Typography
                fontSize="18px"
                fontWeight={600}
                component="h2"
                data-testid="page-title"
              >
                Excluded Accounts List:
                {data?.excluded_customers_list.length > 0 && (
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    ({data.excluded_customers_list.length} account(s) selected)
                  </span>
                )}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  mb: "20px",
                }}
              >
                <AccountSelect
                  label="Exclude an account"
                  name="account"
                  required
                  customersList={data?.excluded_customers_list}
                  disabled={isChecked}
                  value={selectedCustomerExclude}
                  onChange={(_e, newValue) =>
                    setSelectedCustomerExclude(newValue)
                  }
                  extraStyles={{
                    mt: 1,
                  }}
                />

                <Button
                  sx={{
                    padding: "4px 5px",
                    fontSize: "12px",
                    minWidth: "0",
                    width: "30px",
                    height: "30px !important",
                    mt: "7px",
                  }}
                  variant="contained"
                  disabled={isChecked}
                  onClick={() => {
                    dispatch({
                      type: REDUCER_ACTIONS.ADD_EXCLUDED_CUSTOMER,
                      payload: { newCustomer: selectedCustomerExclude },
                    });
                    setSelectedCustomerExclude({ label: "", value: "" });
                  }}
                  color="primary"
                >
                  <AddIcon />
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                {data.excluded_customers_list.map((customer) => {
                  return (
                    <CustomerCard
                      customer={customer}
                      setCustomersList={setCustomersListExclude}
                      dispatch={dispatch}
                      exclude={true}
                    />
                  );
                })}
              </Box>
            </Block>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
