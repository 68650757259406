export const DATE_FORMAT_US = "MM/DD/YYYY";
export const DATE_FORMAT_FRONT = "MMM DD, YYYY";
export const DATE_FORMAT_FRONT_H_M = "MMM DD, YYYY HH:mm";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_HOUR = "MM/DD/YYYY HH:mm";

export const DEBOUNCE_VALUE = 500;

export const soldByValMapper = {
  PC: "Piece",
  BX: "Box",
  BK: "Bucket",
  BT: "Batter",
  CT: "Container",
  DZ: "Dozen",
  EA: "Each",
  LB: "Lb",
  PK: "Package",
  PL: "pale / bucket",
  SH: "Sheet",
};

export const INVOICE_STATUS = {
  PAID: "Paid",
  UNPAID: "Unpaid",
  PARTIALLY_PAID: "Partially paid",
  VOIDED: "Voided",
  OVERPAID: "Overpaid",
  CREDIT: "Credit",
};
