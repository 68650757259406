/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Switch, Typography } from "@mui/material";
import DataTable from "../../components/DataTable";
import { useSearchParams } from "react-router-dom";
import {
  fetchAccounts,
  selectAccountNumberOfPages,
  setAccountTableSettings,
  cleanTableSettings,
} from "../../redux/module/account";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import AddAccountForm from "./AddAccountForm";
import accountTableColumns from "./accountTableColumns";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import PageLoading from "../../components/PageLoading";
import { downloadFile } from "../../distflowAPI/downloadFile";
import { listAccounts } from "../../distflowAPI/accountsApi";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const Accounts = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams({
    ordering: "internal_id",
  });
  const [accounts, setAccounts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [accountsLoading, setAccountsLoading] = useState(false);
  useEffect(() => {
    setAccountsLoading(true);
    listAccounts(pageSize, page, "", searchParams).then((res) => {
      setAccounts(res.data.results);
      setAccountsLoading(false);
    });
  }, [pageSize, page, searchParams]);

  const { authUser } = useAppSelector((state) => state.user);
  const [myCustomers, setMyCustomers] = useState(false);
  const [activeOnly, setActiveOnly] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleShowMyChange = (_, checked) => {
    setMyCustomers(checked);
  };
  const handleActiveOnle = (_, checked) => {
    setActiveOnly(checked);
  };
  useEffect(() => {
    handleChange("filterBy", [{ column: "myCustomers", value: myCustomers }]);
  }, [myCustomers]);
  useEffect(() => {
    handleChange("filterBy", [{ column: "active", value: activeOnly }]);
  }, [activeOnly]);
  const controlled = ["active", "myCustomer"];
  function handleChange(target, values, default_) {
    console.log("handle change: ", target, values);
    if (target == "ordering") {
      setSearchParams((params) => {
        const newParams = new URLSearchParams(params);
        newParams.set("ordering", values);
        return newParams;
      });
    } else {
      const newParams = new URLSearchParams();
      newParams.set("myCustomers", myCustomers);
      newParams.set("active", activeOnly);
      newParams.set("ordering", searchParams.get("ordering"));
      values.map((val) => {
        newParams.set(val.column, val.value);
        return newParams;
      });
      setSearchParams(newParams);
    }
  }
  return (
    <>
      <PageLoading dataLoading={accountsLoading} />

      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Accounts
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
          width="100%"
        >
          <Stack direction={"row"} spacing={1}>
            <Button onClick={handleOpen} variant="contained">
              Add New
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                downloadFile(
                  "/api/accounts/accounts_export/",
                  "accounts_export.csv"
                )
              }
            >
              Export
            </Button>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Stack direction="row" alignItems="center">
              <Typography>Customers I manage</Typography>
              <Switch
                checked={myCustomers}
                onChange={handleShowMyChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography>Active?</Typography>
              <Switch
                checked={activeOnly}
                onChange={handleActiveOnle}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Box className="table-wrapper">
        <DataTable
          rowData={accounts}
          columnDefs={accountTableColumns}
          defaultColDef={defaultColDef}
          loading={accountsLoading}
          suppressSorting={true} //disable default sorting
          rowHeight={53}
          headerHeight={60}
          onSortChanged={(event) =>
            handleChange(
              "ordering",
              formatAgGridSorting(event.columnApi.columnModel.gridColumns),
              []
            )
          }
          onFilterChanged={(event) => {
            handleChange(
              "filterBy",
              formatAgGridFilters(event.api.getFilterModel())
            );
          }}
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => setPageSize(pageSize)}
          noPages={numberOfPages}
          setPage={(page) => setPage(page)}
        />
      </Box>
      <AddAccountForm open={open} handleClose={handleClose} />
    </>
  );
};

export default Accounts;
