import { Typography, Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { REDUCER_ACTIONS } from "./config";

export default function CustomerCard({ customer, dispatch, exclude = false }) {
  function deleteCustomer() {
    if (exclude) {
      dispatch({
        type: REDUCER_ACTIONS.DELETE_EXCLUDED_CUSTOMER,
        payload: customer,
      });
    } else {
      dispatch({
        type: REDUCER_ACTIONS.DELETE_CUSTOMER,
        payload: customer,
      });
    }
  }

  return (
    <>
      <Box
        sx={{
          borderRadius: "8px",
          padding: "5px 10px",
          border: "1px solid #cecece",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>{customer?.label}</Typography>
        <Button
          sx={{
            padding: "4px 5px",
            fontSize: "12px",
            minWidth: "0",
            width: "30px",
            height: "30px !important",
          }}
          variant="contained"
          onClick={() => {
            deleteCustomer();
          }}
          color="error"
          size="small"
        >
          <DeleteIcon fontSize="small" />
        </Button>
      </Box>
    </>
  );
}
