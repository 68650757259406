import api from "./api";
import ApiBase from "./apiBase";
import axios from "axios";
const accountsApi = new ApiBase("/accounts/accounts");

export default accountsApi;
export async function createAccountApi(payload) {
  const result = await api.post(`/api/accounts/accounts/`, payload);
  return result;
}

export async function updateAccountApi(accountId, payload) {
  const result = await api.patch(
    `/api/accounts/accounts/${accountId}/`,
    payload
  );
  return result;
}

// Notes Account

export async function getNoteAccount(accountId) {
  const result = await api.get(
    `/api/accounts/account_notes/?account__id=${accountId}`
  );
  return result;
}
export async function postAccountNote(id, data) {
  const result = await api.post(`/api/accounts/account_notes/`, {
    account: id,
    note: data,
  });
  return result;
}
export async function updateNoteAccount(noteId, note, account) {
  const result = await api.patch(`/api/accounts/account_notes/${noteId}/`, {
    account: account,
    note: note,
  });
  return result;
}
export async function deleteNoteAccount(noteId) {
  const result = await api.delete(`/api/accounts/account_notes/${noteId}/`);
  return result;
}

// Account Restrictions

export async function getAccountById(account_id) {
  const result = await api.get(`/api/accounts/accounts/${account_id}/`);
  return result;
}

export function patchAccount(accountId, accountInfo) {
  return api.patch(`/api/accounts/accounts/${accountId}/`, accountInfo);
}

export async function getAccountApi(accountId) {
  const result = await api.get(`/api/accounts/accounts/${accountId}/`);
  return result;
}

export async function getAccountNotes(accountId) {
  const result = await api.get(`/api/accounts/accounts/${accountId}/`);
  return result;
}

export async function listAccountOverrides(accountId) {
  const result = await api.get(
    `/api/accounts/accounts/${accountId}/overrides/`
  );
  return result;
}

export function getAccountOverrides(accountId) {
  return api.get(`/api/accounts/accounts/${accountId}/overrides/`);
}
export function cloneAccount(accountId, payload) {
  return api.post(`/api/accounts/accounts/${accountId}/clone/`, payload);
}
export function postAccountOverride(accountId, data) {
  return api.post(`/api/accounts/accounts/${accountId}/overrides/`, data);
}

export function patchAccountOverride(id, overrideId, payload) {
  return api.patch(
    `/api/accounts/accounts/${id}/overrides/${overrideId}/`,
    payload
  );
}

export function deleteAccountOverride(accountId, overrideId) {
  return api.delete(
    `/api/accounts/accounts/${accountId}/overrides/${overrideId}/`
  );
}

export async function listAccounts(pageSize, page, search, searchParams) {
  const result = await api.get(
    `/api/accounts/accounts/?search=${search}&page_size=${pageSize}&page=${page}&${searchParams}`
  );
  return result;
}

// Accounts category restrictions :
export async function getCategoryAccountRestrictions(
  accountId,
  pageSize,
  page
) {
  const params = `accountId=${accountId}&page_size=${pageSize}&page=${page}`;
  const result = await api.get(
    `/api/products/category_account_restrictions/?${params}`
  );
  return result;
}

export async function getVendorAccountRestrictions(accountId, pageSize, page) {
  const params = `&page_size=${pageSize}&page=${page}`;
  const result = await api.get(
    `/api/accounts/accounts/${accountId}/vendor_restrictions/?${params}`
  );
  return result;
}
export async function deleteVendorAccountRestriction(accountId, id) {
  const result = await api.delete(
    `/api/accounts/accounts/${accountId}/vendor_restrictions/?${id}/`
  );
  return result;
}
export async function createVendorAccountRestrictions(
  accountId,
  vendorId,
  note
) {
  const result = await api.post(
    `/api/accounts/accounts/${accountId}/vendor_restrictions/`,
    {
      vendor: vendorId,
      customer: accountId,
      note: note,
    }
  );
  return result;
}
export async function postCategoryAccountRestrictions(
  accountId,
  categoryId,
  note
) {
  const result = await api.post(
    `/api/products/category_account_restrictions/`,
    {
      category: categoryId,
      customer: accountId,
      note: note,
    }
  );
  return result;
}

export async function deleteCategoryAccountRestrictions(restrictionId) {
  const result = await api.delete(
    `/api/products/category_account_restrictions/${restrictionId}/`
  );
  return result;
}

export async function listAccountEmails(accountId) {
  const res = await api.get(`/api/accounts/accounts/${accountId}/emails/`);
  return res;
}
export async function createAccountEmail(accountId, email) {
  const res = await api.post(`/api/accounts/accounts/${accountId}/emails/`, {
    account: accountId,
    email,
  });
  return res;
}

export async function updateAccountEmail(
  accountId,
  emailId,
  email,
  isPrimary = false
) {
  const res = await api.patch(
    `/api/accounts/accounts/${accountId}/emails/${emailId}/`,
    {
      email,
      is_default: isPrimary,
    }
  );
  return res;
}

export async function deleteAccountEmail(accountId, emailId) {
  const res = await api.delete(
    `/api/accounts/accounts/${accountId}/emails/${emailId}/`
  );
  return res;
}

export async function listAccountPhones(accountId) {
  const res = await api.get(`/api/accounts/accounts/${accountId}/phones/`);
  return res;
}
export async function createAccountPhone(accountId, phone, note) {
  const res = await api.post(`/api/accounts/accounts/${accountId}/phones/`, {
    account: accountId,
    phone,
    note,
  });
  return res;
}

export async function updateAccountPhone(
  accountId,
  phoneId,
  phone,
  isPrimary = false,
  note
) {
  const res = await api.patch(
    `/api/accounts/accounts/${accountId}/phones/${phoneId}/`,
    {
      phone,
      is_default: isPrimary,
      note,
    }
  );
  return res;
}

export async function deleteAccountPhone(accountId, phoneId) {
  const res = await api.delete(
    `/api/accounts/accounts/${accountId}/phones/${phoneId}/`
  );
  return res;
}
