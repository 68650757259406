import api from "./api";
import { filtersToApi, orderingToApi } from "./common";
import ApiBase from "./apiBase";

const invoicesApi = new ApiBase("/accounting/invoices");

export default invoicesApi;

export async function listInvoices(
  page = 1,
  pageSize = 100,
  filter = [],
  order = []
) {
  const orderingParam = order.length ? `&ordering=${orderingToApi(order)}` : "";
  const filterParam = filter.length ? `&${filtersToApi(filter)}` : "";
  const params = `page_size=${pageSize}&page=${page}${orderingParam}${filterParam}`;
  const res = await api.get(`/api/accounting/invoices/?${params}`);
  return res;
}

export async function getInvoice(id) {
  const res = await api.get(`/api/accounting/invoices/${id}/`);
  return res;
}

export async function emailInvoice(id) {
  return api.get(`/api/accounting/email_invoice/${id}/`);
}

export async function updateInvoice(id, payload) {
  const res = await api.patch(`/api/accounting/invoices/${id}/`, payload);
  return res;
}
export async function createInvoice(payload) {
  const res = await api.post(`/api/accounting/invoices/`, payload);
  return res;
}

export async function listStatements(page, pageSize, orderBy, filterBy) {
  const res = await api.get(
    `/api/accounting/statement_reports/?page_size=${pageSize}&page=${page}`
  );
  return res;
}

export async function generateStatement(
  start_date,
  end_date,
  customer_id,
  account_id,
  settings
) {
  let url = `/api/accounting/invoices_download/`;
  const res = await api.post(url, {
    download_type: "invoices",
    start_date,
    end_date,
    customer_id,
    account_id,
    settings,
  });
  return res;
}

export async function getSeneratedStatement(id) {
  let url = `/api/accounting/statement_reports/${id}/`;
  const res = await api.get(url);
  return res;
}

export async function listTransactions(
  page,
  pageSize,
  filterBy,
  orderBy,
  searchParams
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy);
  let filterByS = filtersToApi([], searchParams);
  const params = `?page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}&${filterByS}`;
  const res = await api.get(`/api/accounting/transactions/${params}`);
  return res;
}

export async function createTransaction(payload) {
  const res = await api.post("/api/accounting/transactions/", payload);
  return res;
}

export async function updateTransaction(id, payload) {
  const res = await api.patch(`/api/accounting/transactions/${id}/`, payload);
  return res;
}
export async function deleteTransaction(id) {
  const res = await api.delete(`/api/accounting/transactions/${id}/`);
  return res;
}
export async function updateInvoiceStatus(invoiceId) {
  const res = await api.get(
    `/api/accounting/update_invoice_status/?invoice_id=${invoiceId}`
  );
  return res;
}

export async function listStatementGroups(pageSize, page, ordering, filterBy) {
  const filterByString = filtersToApi(filterBy);
  const res = await api.get(
    `/api/accounting/statement_groups/?page_size=${pageSize}&page=${page}&${filterByString}`
  );
  return res;
}

export async function createStatementGroup(payload) {
  const res = await api.post(`/api/accounting/statement_groups/`, payload);
  return res;
}

export async function updateStatementGroup(id, payload) {
  const res = await api.patch(
    `/api/accounting/statement_groups/${id}/`,
    payload
  );
  return res;
}

export async function deleteStatementGroup(id) {
  const res = await api.delete(`/api/accounting/statement_groups/${id}/`);
  return res;
}

export async function processStatementGroup(selectedGroup, st, ed) {
  const res = await api.get(
    `/api/accounting/generate_group_statement/?startDate=${st}&endDate=${ed}&groupId=${selectedGroup}`
  );
  return res;
}

export async function listGroupStatementBatches(gId) {
  const res = await api.get(
    `/api/accounting/statement_groups_entries/?gId=${gId}&ordering=-id`
  );
  return res;
}

export async function listBatchStatements(
  bId,
  has_sge = true,
  page,
  filters,
  pageSize
) {
  let params = `?has_sge=${has_sge}`;
  if (bId) {
    params = params += `&bId=${bId}`;
  }
  Object.keys(filters || {}).map((k) => {
    params += `&${k}=${filters[k]}`;
  });
  const res = await api(
    `/api/accounting/account_statement/${params}&page=${page}&page_size=${pageSize || 50}`
  );
  return res;
}
export async function emailSGE(bid) {
  const res = await api(`/api/accounting/email_sge/?sge_id=${bid}`);
  return res;
}
export async function generateWeekly(startDate, endDate) {
  const res = await api(
    `/api/accounting/generate_weekly_invoices/?start_date=${startDate}&end_date=${endDate}`
  );
  return res;
}

export async function emailWeekly() {
  const res = await api(`/api/accounting/email_weekly_invoices/`);
  return res;
}

export async function purgeUnsentWeekly() {
  const res = await api(`/api/accounting/purge_unsent_weekly_invoices/`);
  return res;
}

export async function listBalances(page, pageSize, filters, orderBy) {
  let url = `/api/accounting/balances/?page=${page}&page_size=${pageSize}&ordering=${orderBy}`;
  Object.keys(filters).map((k) => {
    if (filters[k]) {
      url += `&${k}=${filters[k]}`;
    }
  });
  console.log("COLLECTION FILTERS ARE;: ", filters);
  const res = await api.get(url);
  return res;
}

export async function listAccountTasks(accountId) {
  return api.get(`/api/accounting/account_tasks/?account_id=${accountId}`);
}

export async function createAccountTasks(payload) {
  const res = await api.post(`/api/accounting/account_tasks/`, payload);
  return res;
}

export async function patchAccountTask(tId, payload) {
  const res = await api.patch(`/api/accounting/account_tasks/${tId}/`, payload);
  return res;
}

export async function deleteAccountTask(taskId) {
  const res = await api.delete(`/api/accounting/account_tasks/${taskId}/`);
  return res;
}

export async function getInvoiceHistory(invoiceId) {
  const res = await api.get(
    `/api/accounting/invoices_history/?id=${invoiceId}`
  );
  return res;
}
