import { useEffect, useState } from "react";
import {
  emailWeekly,
  generateWeekly,
  listBatchStatements,
  purgeUnsentWeekly,
} from "../../distflowAPI/invoicesApi";
import {
  Button,
  Grid,
  Stack,
  Typography,
  Checkbox,
  TextField,
} from "@mui/material";
import DataTable from "../../components/DataTable";
import Block from "../../components/Block";
import { downloadFile } from "../../distflowAPI/downloadFile";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import DatePickerCustom2 from "../../components/DatePicker2";
import { getNoPages } from "../../utils";

const batchStatsCols = [
  {
    headerName: "Account",
    field: "account.name",
    flex: 4,
  },
  {
    headerName: "Start Date",
    valueGetter: (params) => {
      return `${dayjs(params?.data?.start_date).format(DATE_FORMAT_FRONT)} `;
    },
    flex: 2,
  },
  {
    headerName: "End Date",
    valueGetter: (params) => {
      return `${dayjs(params?.data?.end_date).format(DATE_FORMAT_FRONT)}`;
    },
    flex: 2,
  },
  {
    headerName: "Note",
    valueGetter: (params) => params.data.comment,
    flex: 4,
  },
  {
    headerName: "Emailed",
    field: "emailed",
    flex: 1,
  },
  {
    headerName: "Actions",
    cellRenderer: (params) => (
      <Button
        onClick={() =>
          downloadFile(
            params.data.file,
            `${params.data.account.name} ${params.data.start_date} ${params.data.end_date}.pdf`,
            true
          )
        }
      >
        Download
      </Button>
    ),
    flex: 1,
  },
];

function StatementsList() {
  const [filters, setFilters] = useState({
    ignored: false,
    ordering: "",
  });
  const [statements, setStatements] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [success, setSuccess] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);
  const [noPages, setNoPages] = useState(1);
  const [isPurging, setIsPurging] = useState(false);
  function updateTable() {
    listBatchStatements(null, false, page, filters, pageSize).then((res) => {
      setStatements(res.data.results);
      setNoPages(getNoPages(res.data.count, pageSize));
    });
  }

  useEffect(() => {
    if (page == 1) {
      updateTable();
    }
  }, [filters, pageSize]);
  useEffect(() => {
    updateTable();
  }, [page]);
  function handleEmailWeekly() {
    setGenerating(true);
    emailWeekly()
      .then((res) => {
        updateTable();
        setSuccess(
          "Started emailing process, takes time, refresh the page to see changes"
        );
      })
      .finally(() => setGenerating(false));
  }

  function handleGenerateWeekly() {
    setGenerating(true);
    generateWeekly(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"))
      .then((res) => {
        updateTable();
        setSuccess(
          "Started weekly invoices generation process, takes time, refresh the page to see changes"
        );
      })
      .finally(() => setGenerating(false));
  }
  function handlePurge() {
    setIsPurging(true);
    purgeUnsentWeekly()
      .then((res) => {
        updateTable();
        setSuccess("Unsent weekly emails purged successfully");
      })
      .finally(() => setIsPurging(false));
  }
  function handleSubmit() {
    alert("email all");
  }
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  return (
    <Block>
      <Stack spacing={1}>
        <Stack direction={"row"} spacing={2}>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleEmailWeekly()}
            disabled={generating}
          >
            email all
          </Button>
          <DatePickerCustom2
            controlledValue={startDate}
            setControlledValue={setStartDate}
            label={"start date"}
          />
          <DatePickerCustom2
            controlledValue={endDate}
            setControlledValue={setEndDate}
            label={"end date"}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleGenerateWeekly()}
            disabled={
              generating ||
              !startDate?.isValid() ||
              !endDate?.isValid() ||
              (startDate.isValid() &&
                endDate.isValid() &&
                endDate.isBefore(startDate))
            }
          >
            Generate
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handlePurge()}
            disabled={isPurging}
          >
            Purge
          </Button>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              onChange={(e) => {
                setFilters({ ...filters, ignored: e.target.checked });
              }}
              checked={filters.ignored}
            />
            <Typography>Show ignored?</Typography>
          </Stack>
          <TextField
            label="account"
            onChange={(e) => {
              setFilters({ ...filters, account__name: e.target.value });
            }}
            checked={filters.account__name}
          />
        </Stack>
        <Grid container>
          <Grid md={12}>
            <DataTable
              columnDefs={batchStatsCols}
              rowData={statements}
              rowHeight={53}
              headerHeight={60}
              page={page}
              pageSize={pageSize}
              noPages={noPages}
              setPageSize={(e) => setPageSize(e)}
              setPage={(e) => setPage(e)}
            />
          </Grid>
        </Grid>
      </Stack>
    </Block>
  );
}

export default StatementsList;
