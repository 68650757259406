import React from "react";
import AsyncAutocompleteSelect from "./AsyncAutocompleteSelect";
import accountsApi from "../../distflowAPI/accountsApi";

/**
 * @param {Omit<
 *  import("./AsyncAutocompleteSelect").AsyncAutocompleteSelectProps<
 *    import("../../redux/module/category").Category
 *  >,
 *  "fetchOptions"
 * >} props
 */
const AccountSelect = (props) => {
  //temporarily the only field we need is customerId
  const filters = [];
  if (!isNaN(props.customerId)) {
    filters.push({ column: "customer", value: props.customerId });
  }

  return (
    <AsyncAutocompleteSelect
      fetchOptions={(searchTerm) =>
        accountsApi
          .list(
            50,
            1,
            [],
            [{ column: "search", value: searchTerm }].concat(filters)
          )
          .then((res) =>
            res.results.map((item) => ({
              value: item.id,
              label: `${item.name} (${item.internal_id}) ${item?.active ? "" : " - INACTIVE"}`,
              ...item,
            }))
          )
      }
      getOptionDisabled={(option) => !option?.active}
      {...props}
    />
  );
};

export default AccountSelect;
