import React, { useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import DataTable from "../DataTable";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import {
  cleanTableSettings,
  fetchCustomerPayments,
  selectCustomerNumberOfPages,
  setCustomerTableSettings,
} from "../../redux/module/customer";
import paymentTableColumns from "../../pages/payments/paymentTableColumns";
import Spinner from "../Spinner";
import NoOptionsBlock from "./NoOptionsBlock";
import PaymentFilters from "../../pages/payments/PaymentFilters";
import { listPayments } from "../../distflowAPI/paymentsApi";
import { useState } from "react";
import {
  filtersToApi,
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import { getNoPages } from "../../utils";
import { downloadFile } from "../../distflowAPI/downloadFile";
import { getCustomerById } from "../../distflowAPI/customersApi";
const defaultColDef = { flex: 1, minWidth: 100 };

const CustomerPayments = ({ accounts }) => {
  const { id } = useParams();
  console.log(
    "customer id is: ",
    id,
    formatAgGridFilters({ account__customer__id: id })
  );
  console.log(
    "customer formatted filter is: ",
    formatAgGridFilters({
      account__customer__id: {
        value: id,
        filterType: "equals",
        filter: id,
      },
    })
  );
  const [searchParams, setSearchParams] = useSearchParams({
    ordering: "-payment_date",
  });
  const [payments, setPayments] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [filterBy, setFilterBy] = useState({});
  const [orderBy, setOrderBy] = useState([]);
  const [paymentsLoading, setPaymentsLoading] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [customer, setCustomer] = useState({});
  useEffect(() => {
    getCustomerById(id).then((res) => {
      console.log("customer res is: ", res);
      setCustomer(res.data);
    });
  }, [id]);
  function fetchPayments() {
    listPayments(
      page,
      pageSize,
      formatAgGridFilters({
        ...filterBy,
        customer__id: {
          value: id,
          filterType: "equals",
          filter: id,
        },
      }),
      orderBy,
      searchParams
    ).then((res) => {
      setPayments(res.data.results);
      setNumberOfPages(getNoPages(res.data.count, pageSize));
    });
  }
  useEffect(() => {
    fetchPayments();
  }, [pageSize, page, filterBy, searchParams]);
  function handleSortParams(value) {
    let newSortingValue = "";
    if (value?.length > 0) {
      newSortingValue = value[0]?.field;
      if (value[0]?.sort != "asc") {
        newSortingValue = "-" + newSortingValue;
      }
    }
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("ordering", newSortingValue);
      return newParams;
    });
  }
  const handleChange = (field, value) => {
    if (field === "orderBy") {
      handleSortParams(value);
    }
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography component="p" variant="h6">
          Customer payments
        </Typography>
        <Stack direction={"row"} spacing={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/invoices/payments/new?customer_id=${id}`)}
          >
            Create Payment
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              const filters = formatAgGridFilters({
                ...filterBy,
                customer_id: {
                  value: id,
                  filterType: "equals",
                  filter: id,
                },
              });
              console.log("filters are: ", filters);
              let filterByS = filtersToApi([], searchParams);
              console.log("filters s: ", filterByS);
              downloadFile(
                `/api/accounting/group_payments_download/?customer_id=${id}&${filterByS}`,
                `${customer?.name ? customer?.name + "-" : ""}Payments.zip`
              );
            }}
          >
            Download All Payments
          </Button>
        </Stack>
      </Stack>
      {(!!payments.length || searchParams.toString() !== "") && (
        <PaymentFilters />
      )}
      {paymentsLoading ? (
        <Box
          height="200px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="lg" />
        </Box>
      ) : payments.length ? (
        <DataTable
          rowData={payments}
          columnDefs={paymentTableColumns}
          defaultColDef={defaultColDef}
          rowHeight={73}
          page={page}
          pageSize={pageSize}
          loading={paymentsLoading}
          onSortChanged={(e) => {
            handleChange(
              "orderBy",
              formatAgGridSorting(e.columnApi.columnModel.gridColumns)
            );
          }}
          setPageSize={(pageSize) => setPageSize(pageSize)}
          noPages={numberOfPages}
          setPage={(page) => setPage(page)}
        />
      ) : (
        <NoOptionsBlock
          option="payments"
          isFiltered={searchParams.keys().next().done === false}
        />
      )}
    </Box>
  );
};

export default CustomerPayments;
