import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const UpdateItemTitle = ({
  item,
  setIsOpenDelete,
  setIsOpenDiscard,
  isSubmitting,
  backLink,
  canClone,
  onClone,
  addButtons = [],
}) => {
  const navigate = useNavigate();

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      alignItems={{ xs: "flex-start", md: "center" }}
      justifyContent="space-between"
      spacing="20px"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
      >
        <Button
          onClick={() => navigate(backLink || -1)}
          variant="outlined"
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Typography
          variant="pageTitle"
          noWrap
          component="p"
          data-testid="page-title"
        >
          Update{` ${item}`}
        </Typography>
      </Stack>
      <Stack
        direction={{ sm: "row-reverse", md: "row" }}
        alignItems="center"
        justifyContent="flex-end"
        flexWrap="wrap"
        sx={{
          width: {
            xs: "100%",
            sm: "initial",
          },
          gap: "11px",
          "@media(max-width: 600px)": {
            flexDirection: "row-reverse",
            width: "100%",
          },
          "@media(max-width: 480px)": {
            flexDirection: "column-reverse",
            width: "100%",
            button: { width: "100%" },
          },
        }}
      >
        {setIsOpenDelete && (
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={() => setIsOpenDelete(true)}
          >
            Delete
          </Button>
        )}
        <Button
          size="small"
          variant="outlined"
          onClick={() => setIsOpenDiscard(true)}
        >
          Discard
        </Button>
        <Button
          size="small"
          color="success"
          variant="contained"
          type="submit"
          disabled={isSubmitting}
        >
          Save & continue Editing
        </Button>
        {canClone && (
          <Button
            size="small"
            variant="outlined"
            disabled={isSubmitting}
            onClick={onClone}
          >
            Clone
          </Button>
        )}
        {addButtons &&
          Array.isArray(addButtons) &&
          addButtons.map((addb) => <>{addb}</>)}
      </Stack>
    </Stack>
  );
};

export default UpdateItemTitle;
